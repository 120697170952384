<template>
  <div class="newsWrap">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="newsBar mb20">
          <template v-if="type == 0">
            <div class="title">家族明星赛模式</div>
            <div class="des">How To Play It</div>
            <div class="con">
              <p>创建房间时，选择【家族明星赛】，在房间里的游戏模式中，选择【家族明星赛】便可开始【家族明星赛】。</p>
              <p>仅限双方家族成员之间的斗舞，支持2v2，3v3，4v4，每队玩家必须是同一个家族的。</p>
              <p>在家族明星赛房间开设后，需要房主选择比赛模式。</p>
              <p>比赛结束后，会获得相应的家族点数。</p>
            </div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail1.png')" alt="">
            </div>
            <div class="title">经典模式</div>
            <div class="des des1">【 How To Play 普通模式】</div>
            <div class="con">
              <p>普通模式中又分为 【个人战】和 【组队战】，且都有4键和8键2种操作模式。</p>
              <p>通过方向键【←】【→】【↑】【↓】和 空格，或者是小键盘里的 12346789和空格，来进行游戏。</p>
              <p>在普通模式的房间中，玩家可以根据自己喜好，开展劲舞经典模式：普通个人模式，自由模式同步模式四键，同步模式八键，高级同步模式 八键，狂舞模式 (11K)四键狂舞模式(11K)八键。</p>
            </div>
            <div class="des des1">【How To Play 情侣模式】</div>
            <div class="con">
              <p>在普通模式下进入房间，将模式改为【组队战】就能选择【情侣模式】了。</p>
              <p>如果是孤身一人的话，是无法开启【情侣模式】的。</p>
              <p>当两人同时打出【Perfect】就可以获得一个红心，红心数量满5个后，就可以互相申请成为【密友】。</p>
            </div>
            <div class="title">新同步模式</div>
            <div class="des">How To Play It</div>
            <div class="con">
              <p>新同步模式分为【个人战】和【组队战】，且都有4键和8键2种操作模式。</p>
              <p>新同步模式的基本玩法与同步模式相同，方向键【←】【→】【↑】【↓】和 空格，或者是小键盘里的123467897和空格，来进行游戏。</p>
              <p>FINISH MOVE的第2小节和第3小节舞步是额外经验的敲打时间，在这个时间段内敲击空格的速度越快获得的经验也就越多。</p>
            </div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail2.png')" alt="">
            </div>
            <div class="con">
              <p class="red">
                <span class="red">如何获得额外经验条:</span>
                在游戏过程中,玩家获得GREAT、PERFECT判定后,会获得相应的额外经验条的累加，每个FINISH MOVE之前玩家最多可以获得3个额外经验条。
              </p>
            </div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail3.png')" alt="">
            </div>
            <div class="con">
              <p class="red">
                <span class="red">怎样使用额外经验条: </span>
                在每次FINISH MOVE后，会有2小节NOTES的额外经验击打时间，在这个时间段内玩家通过频繁击打空格键来获得额外经验值,每击打一次按键,额外经验值就增加一定数值 (根据累计经验条数增加)。
              </p>
            </div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail4.png')" alt="">
            </div>
            <div class="con">
              <p class="red">
                <span class="red">结算画面: </span>
                结算时不仅能看到自己获得的额外经验，还能看到其他玩家获得的额外经验,快来比比看谁的手更快吧。
              </p>
            </div>
          </template>
          <template v-else-if="type == 1">
            <div class="title">情侣系统</div>
            <div class="des des1">【如何成为密友】</div>
            <div class="con">
              <p>选择情侣模式或CLUB DANCE 模式，双方在同一回合同时跳出PERFECT即可得到一颗心。</p>
              <p>一首歌积攒 5颗心或以上，在结算画面中就会出现 【申请密友】按钮。</p>
              <p>选择 CLUB DANCE II模式，双方在结算时获得60或以上红心,即可出现【申请密友】的按钮。</p>
              <p>或者使用【爱情药水】道具，并进行情侣模式后出现【申请密友】按钮。</p>
              <p>双方同意结为密友后，在双方个名片的密友栏中显示对方的昵称。</p>
            </div>
            <div class="des des1">【如何成为情侣】</div>
            <div class="con">
              <p>双方是密友关系。</p>
              <p>选择情侣模式或CLUB DANCE 模式，每共同完成一首歌，即可获得一个KISS。</p>
              <p>KISS数量达到5个时，由男方在商城购买 【情侣派对卡】能顺利完成【情侣派对】里的任务,那么恭喜二位,终于有情人终成眷属。</p>
            </div>
            <div class="des des1">【成为情侣后的好处】</div>
            <div class="con">
              <p>获得情侣戒指，并可升级。</p>
              <p>获得情侣登记证。</p>
              <p>获得礼金。</p>
            </div>
            <div class="des des1">【如何解除情侣关系】</div>
            <div class="con">
              <p>在商城购买道具【忘情水】。 </p>
              <p>点击【断绝情侣关系】的按钮，并且确认断绝。</p>
            </div>
            <div class="title">家族系统</div>
            <div class="des">如何建立家族</div>
            <div class="con">
              <p>人物等级达到15级，并且拥有足够M币的玩家，就可以在【家族中心】建立自己的家族。 </p>
            </div>
            <div class="des">如何加入家族</div>
            <div class="con">
              <p>进入【家族中心】后，点击【加入家族】在所有的家族列表中,选择自己喜欢的家族发出【申请】获得该家族官员批准后，即可加入家族。</p>
            </div>
            <div class="des">家族有什么功能</div>
            <div class="con">
              <p>家族设施： 每个家族都有自己的家族基地,家族公馆,家族基金,家族论坛，家族大事记,以及家族招募栏。</p>
              <p>家族徽章：家族族长在创建家族的时候，可以选择自己家族的家族徽章。创建家族完成后该家族的每个家族成员的昵称前都会显示本家族的家族微章</p>
              <p>家族战：2个家族之间可以开展激烈的家族战，赢得家族战胜利的家族会获得家族点数。</p>
              <p>家族任务：玩家可以在任务窗口里，接受家族任务(如下图)。每天完成特定的家族任务可以获得高额任务奖励。</p>
            </div>
            <div class="des">如何脱离家族</div>
            <div class="con">
              <p>点击【家族脱离】，弹出对话框: 【是否脱离XXX家族 ?】选择【确定】后，直接返回家族中心页面。</p>
            </div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail5.png')" alt="">
            </div>
            <div class="title">伙伴系统</div>
            <div class="des des1">【伙伴分类】</div>
            <div class="con">
              <p>伙伴分为普通伙伴和炫舞伙伴。</p>
              <p>炫舞伙伴可成长</p>
            </div>
            <div class="des des1">【炫舞伙伴升级有什么用】</div>
            <div class="con">
              <p>跟随玩家参加2人以上的游戏。</p>
              <p>主人给伙伴喂养饼干、糖果 (1小时1次，共用CD) 或蛋糕（可无限购买，没有CD）。</p>
            </div>
            <div class="des des1">【如何喂养炫舞伙伴】</div>
            <div class="con">
              <p>在伙伴小屋中，点击[休息]按钮下的[喂养]按钮，给伙伴购买饼干,糖果，或者蛋糕(消耗M币）</p>
            </div>
            <div class="des des1">【如何进入伙伴小屋】</div>
            <div class="con">
              <p>【人物讯息档】中点击 <img :src="require('@/assets/imgs/detail/detail9.png')" alt="">【伙伴】按钮可以打开伙伴窗口。</p>
              <p>点击 <img :src="require('@/assets/imgs/detail/detail10.png')" alt="">【携带】按钮可以更换或携带伙伴。</p>
              <p>进入【小屋】后，点击【伙伴小屋】。</p>
            </div>
            <div class="des des1">【炫舞伙伴升级有什么用】</div>
            <div class="con">
              <p class="red"><span class="red">伙伴模式:</span>只有携带伙伴才可以开启伙伴模式的游戏。</p>
              <p class="red"><span class="red">炫舞伙伴动作: </span>右键点击伙伴，可让伙伴做它自己独有的动作：<span class="red">单体动作</span>(伙伴自己做动作)。</p>
              <p class="red"><span class="red">互动动作</span>(伙伴和主人一起做动作），以及<span class="red">状态动作</span>(伙伴和主人一直保持着一种状态动作，直到主人解除该状态动作，如骑乘)。</p>
              <p class="red"><span class="red">时尚装扮：</span>一个高级漂亮的猫女郎跟在身边是非常抢眼的事儿。</p>
            </div>
          </template>
          <template v-else-if="type == 2">
            <div class="title">人物信息</div>
            <div class="con">
              <p>在游戏大厅内右键点击自己的形象，就会出现【个人讯息框】。</p>
            </div>
            <div class="des des1">【功能按钮】</div>
            <div class="con">
              <p class="red"><span class="red">小屋：</span>进入小屋</p>
              <p class="red"><span class="red">宠物：</span>查看玩家的宠物。</p>
              <p class="red"><span class="red">合成书：</span>打开梦幻合成书。</p>
              <p class="red"><span class="red">我的名片：</span>查看或修改自己的名片。</p>
              <p class="red"><span class="red">名片夹：</span>查看自己所拥有的名片。</p>
            </div>
            <div class="des des1">【基本信息】</div>
            <div class="con">
              <p class="red"><span class="red">昵称：</span>显示玩家的昵称。</p>
              <p class="red"><span class="red">性别：</span>显示玩家人物的性别。</p>
              <p class="red"><span class="red">称号：</span>显示玩家所装备的称号，点击【称号选择】按钮可更改称。</p>
              <p class="red"><span class="red">等级：</span>显示玩家等级。</p>
              <p class="red"><span class="red">声望：</span>显示玩家的声望，声望是玩家在游戏社会活跃性的综合体现。</p>
              <p class="red"><span class="red">舞团成员：</span>显示玩家当前舞团内的所有成员昵称。</p>
              <p class="red"><span class="red">家族信息：</span>显示玩家所在家族的基本信息。</p>
              <p class="red"><span class="red">奖牌信息：</span>显示玩家所获得的奖牌信息。</p>
            </div>
            <div class="title">如何跳舞</div>
            <div class="des des1">【创建舞台】</div>
            <div class="con">
              <p>在大厅内创建各种模式的舞台，或者在小屋中点击【开始跳舞】按钮。</p>
            </div>
            <div class="des des1">【开始跳舞】</div>
            <div class="con">
              <p>进入舞台后，点击【准备】按钮，等待房主点击【开始】按钮后便可跳舞。</p>
            </div>
            <div class="des des1">【舞台功能】</div>
            <div class="con">
              <p class="red"><span class="red">换装：</span>不用切换到商店或者是小屋便可自由换装。</p>
              <p class="red"><span class="red">任务：</span>查看、汇报、接受任务，在舞台中便能轻松解决。</p>
              <p class="red"><span class="red">加好友或家族成员：</span>通过舞台中的列表信息中的玩家昵称，添加自己中意的玩家。</p>
              <p class="red"><span class="red">宠物：</span>右键点击宠物便可查看、喂养、使用宠物动作。</p>
              <p class="red"><span class="red">邮箱：</span>收发纸条，收礼物，统统不用切换到大厅，舞台内便能完成。</p>
              <p class="red"><span class="red">MESSENGER：</span>舞台内置MESSENGER使你跟你的好友交流更加方便。</p>
              <p class="red"><span class="red">喇叭：</span>不用返回大厅也可以随意刷大小喇叭。</p>
            </div>
            <div class="title">如何升级</div>
            <div class="des des1">【获得经验值】</div>
            <div class="con">
              <p>在游戏中可以通过以下途径获得升级所需的经验值</p>
              <p>在与玩家对战的舞蹈比赛中获得名次。</p>
              <p class="red"><span class="red">完成常规任务：</span>常规任务有新手任务、挑战任务、奖励任务、社交任务、结婚任务、师徒任务，通过完成常规任务可获得金币、经验、道具、服装以及 隐藏物品。</p>
              <p class="red"><span class="red">挑战故事模式：</span>挑战故事模式过关后，会有相应的经验奖励。</p>
            </div>
            <div class="des des1">【完成升级任务】</div>
            <div class="con">
              <p class="red"><span class="red">触发条件: </span>级别高于5级，并且已经完成所有的新手任务，每当经验值达到升级要求后将触发相应等级的升级任务，必须通过完成升级任务才可升级。</p>
              <p class="red"><span class="red">接受升级任务：</span>在大厅点击【任务】按钮，选中升级任务之后，点击【接受任务】按钮，然后支付一定G币，便可接受该任务(按下图1-3的顺序接受升级任务）。</p>
            </div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail5.png')" alt="">
            </div>
            <div class="con">
              <p class="red"><span class="red">汇报任务：</span>任务完成后，选中已完成任务的名称，点击【汇报人物】按钮上交任务点击【领取】按钮获得任务奖励。</p>
            </div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail6.png')" alt="">
              <img :src="require('@/assets/imgs/detail/detail7.png')" alt="">
            </div>
            <div class="con">
              <p class="red"><span class="red">领取升级奖励：</span>你需要在自己的中信箱领取任务奖励，点击领取附件，就可以升级了。</p>
            </div>
          </template>
          <template v-else-if="type == 10">
            <div class="des10">1.进入下载页面 <a style="font-size: 20px;" href="https://wx.qtthtech.com/#/downLoad">https://wx.qtthtech.com/#/downLoad</a> ,根据自身需求，选择对应客户端下载（<span>以下以《舞侠OL》绿色免安装完成客户端为例</span> ）。</div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail11.png')" alt="">
            </div>
            <div class="des10">2.点击HTTP下载，选择并记录下载的位置（<span>请不要选择C盘）</span> </div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail12.png')" alt="">
            </div>
            <div class="des10">3.正常网络环境下10-15分钟即可完成整个客户端下载。</div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail13.png')" alt="">
            </div>
            <div class="des10">4.下载完成后找到压缩包所在位置。选中压缩包右键，选择解压到当前文件夹（<span>如果您的电脑中未安装解压缩工具，建议通过百度或者其他应用商店下载，360、WinRAR等均可</span> ）</div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail14.png')" alt="">
            </div>
            <div class="des10">5.解压完成后打开文件夹 <span>舞侠OL，运行DancePatch.exe</span> ，即可打开登录器开始游戏。</div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail15.png')" alt="">
            </div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail16.png')" alt="">
            </div>
            <div class="des10">6.您可 <span>右键DancePatch.exe选择发送到桌面快捷方式</span>以方便您日后查找</div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail17.png')" alt="">
            </div>
            <div class="des10">7.如果您是WIN7操作系统的用户，请下载win7版本专用登录器，并放入舞侠OL文件下，即可在WIN7中正常运营游戏。</div>
            <div class="pic">
              <img :src="require('@/assets/imgs/detail/detail18.png')" alt="">
            </div>
            <div class="title10">以上就是客户端下载解压缩的全部流程了~，如果您还有其他任何问题可以联系在线客服咨询，感谢您对游戏的支持。</div><br>
          </template>
          <template v-else>
            <div class="agreementWrap">
              <div class="title2">《舞侠OL》用户协议</div><br>
              <div class="blod">特别提示：</div>
              <div class="txtInd">上海乾田通亨科技发展有限公司（后简称“乾田网络”）在此特别提醒用户认真阅读本协议，用户可以选择不接受本《协议》（未成年人应在法定监护人陪同下审阅）。</div><br>
              <div class="txtInd">本协议与《乾田网络用户注册协议》是统一整体，用户对本协议的接受即受乾田网络与用户之间全部协议的约束，包括接受乾田网络对服务条款随时所做的任何修改。这些条款可由乾田网络随时更新修订，修改后的服务协议一旦在页面上公布即有效代替原来的服务协议。</div><br>
              <div class="txtInd">如用户不同意本服务协议及/或随时对其的修改，应停止使用乾田网络提供的网络服务。如果用户继续享用乾田网络提供的网络服务，则视为接受乾田网络对服务协议及/或随时对其的修改。</div><br>
              <div class="blod">1. 释义：</div>
              <div class="txtInd">本协议由用户与《舞侠OL》运营商乾田网络之间，关于用户下载、安装、使用、有限复制《舞侠OL》游戏软件及相关服务（以下简称“软件”或“《舞侠OL》”）所订立的协议。</div><br>
              <div class="txtInd">在成为《舞侠OL》注册用户之前，用户应当详细阅读本协议所有内容，用户点选“同意”按钮或进入《舞侠OL》游戏即视为同意接受本协议的约束。</div><br>
              <div class="blod">2. “软件”授权范围：</div>
              <div class="disFlex"><span class="num1">2.1</span> 本协议中是指《舞侠OL》游戏软件，后简称“本软件”。用户可以在单一一台计算机、终端机、工作站或其他数字电子仪器(下统称“计算机”)上安装、使用、显示、运行本软件。</div>
              <div class="disFlex"><span class="num1">2.2</span> 用户还可以在该用户的一个内部储存设备(如内网服务器)上存放或安装一份本软件副本以便在用户的其他计算机上运行本软件；但是，用户必须获得相应授权（如需要）。</div>
              <div class="disFlex"><span class="num1">2.3</span> 保留权利：《舞侠OL》相关的全部权益属于乾田网络所有，除非经明确授权或本协议另有约定，用户不得利用《舞侠OL》游戏注册、登陆、服务活动从事违法犯罪以及不利于乾田网络或其他用户的任何活动。</div>
              <div class="disFlex"><span class="num1">2.4</span> 知识产权声明：本软件的全部知识产权相关权益属于乾田网络所有，包括但不限于文字表述及其组合、图标、图饰、图表、色彩、界面设计、版面框架、游戏数据、用户数据、印刷材料、或电子文档等。《舞侠OL》所有相关内容（包括但不限于游戏名称、程序编码、主题、故事、游戏人物、场景、音响、视觉效果等）的著作权、专利权、商标、商业秘密及其它任何所有权或权利，均属乾田网络所有。用户对本软件仅限于非商业性质的个人使用。</div>
              <div class="disFlex"><span class="num1">2.5</span> 用户只有在获得乾田网络的书面授权之后才能使用这些内容（若使用范围涉及到相关机关审批或/和备案的，则必须完成后方可使用），而不能擅自复制、再造这些内容、或创造与内容有关的派生产品。</div><br>
              <div class="blod">3. 用户的基本义务：</div>
              <div class="disFlex"><span class="num1">3.1</span> 用户应当遵守中华人民共和国法律法规的规定，遵守国家发布的有关游戏管理的政策要求，不得利用本游戏、在游戏时进行（从事）危害国家安全、泄露国家秘密的行为，不得侵犯国家、社会、集体的和公民的合法权益。对于用户违反前述规定的行为，乾田网络有权采取合理措施维护国家、社会公共及自己的合法权益，对于用户的违法犯罪行为，乾田网络将举报司法机关；对于危害乾田网络利益的行为，乾田网络有权采取针对性的措施。</div>
              <div>用户不得利用本游戏制作、复制和传播下列信息：</div>
              <div class="disFlex"><span class="num2">1）</span>煽动抗拒、破坏宪法和法律、行政法规实施的；</div>
              <div>2）煽动颠覆国家政权，推翻社会主义制度的；</div>
              <div>3）煽动分裂国家、破坏国家统一的；</div>
              <div>4）煽动民族仇恨、民族歧视，破坏民族团结的；</div>
              <div>5）捏造或者歪曲事实，散布谣言，扰乱社会秩序的；</div>
              <div>6）宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；</div>
              <div>7）公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；</div>
              <div>8）损害国家机关形象的；</div>
              <div>9）违反宪法、法律法规以及国家发布的其他规定；</div>
              <div>10）用户不得利用本软件从事侵害包括乾田网络在内的他人合法权益的行为。</div>
              <div>3.2 用户提供真实身份信息的义务：</div>
              <div>3.2.1用户同意在注册、登陆游戏、使用乾田网络通行证（后简称“用户账号”）时提供与用户本人身份一致的个人信息，并应当保证前述个人信息完整、准确、真实；对于个人信息发生变更、遗漏、错误、不实的情况，用户应及时补足和修正，否则以此导致的可能损失和不利后果均由用户自行承担，乾田网络不承担任何责任。</div>
              <div>3.2.2 根据国家有关用户信息的规定，用户身份信息中发生变更或失真的部分需要变更的，可以根据乾田网络的要求进行变更，但应本着善意、诚信的原则进行并确保乾田网络以及其他用户不因此发生损失。</div>
              <div>3.3 用户账户和财产的保护措施</div>
              <div>《舞侠OL》游戏服务的所使用的账号与用户注册的账号相同，用户应妥善保管该用户账号及密码,严格按照本协议的约定正确、安全地使用用户账号及密码。乙方因为自身原因造成密码遗失、账号被盗而给自身和他人民事权利造成损害的,除非法律有明确规定，乾田网络不承担法律责任。</div>
              <div>3.4用户如发现所分配的账号或密码被他人非法使用或有使用异常的情况的,应及时根据乾田网络公布的方式举报到乾田网络客服中心,并根据乾田网络相关指示操作，进行账户临时冻结或其他应急操作。</div>
              <div>3.5用户根据上述情况需要采取电子取证、查封虚拟财产、执行虚拟财产等措施时，除根据乾田网络要求提供身份验证资料外，还应根据需要提供相关的司法机关的指令或通知，乾田网络为配合电子取证、查封扣押执行虚拟财产而支出的全部费用和可能损失，应由用户承担。</div>
              <div>3.6 乾田网络根据用户申请采取上述保护措施时，可以对用户提供的信息进行比对，比对发现不相符的，乾田网络将拒绝采取保护措施，全部责任由用户自行承担，必要时可以将相关情况举报到司法机关。</div>
              <div>3.6.1出于保护用户账户信息及虚拟财产安全的需要，乾田网络可能会对较长时间没有进行登录操作且无法联系到的用户账户，进行保护性冻结处理，并通过用户账户内备用电子邮箱及游戏内公告平台给予告知。被保护性冻结的用户，可以在验证注册信息后，重新开通账户。</div>
              <div>3.6.2出于游戏网络资源的集约化以及用户游戏体验需要，游戏用户原则上在同一游戏产品中只能享有一个账户；乾田网络可以对长期未登录且无实际消费的用户账户经一定形式的公告后，定期地进行删除处理。</div>
              <div>3.7 要使用本游戏服务，用户必须：</div>
              <div>3.7.1在乾田网络官方或经过乾田网络官方授权提供的互联网下载地址下载《舞侠OL》客户端；</div>
              <div>3.7.2拥有一个已注册的账号并已接到乾田网络发出的“乾田网络用户帐号已激活相关游戏大区，可以登陆游戏”的官方通知；</div>
              <div>3.7.3确保自己所用设备和互联网接入设施能够连接上互联网并可确保正常运行本软件。</div>
              <div>除此之外，用户必须自行负担相关网络服务有关的设备及软件及费用。无论是本协议或用户的账号都不保证用户可以顺利进行游戏的更新或是资料片。用户必须持续了解并根据我们在对于游戏随时进行的更新公告，对硬件、软件进行相应升级或调整以增进游戏功能，否则我们将不保证用户可以持续进行游戏。</div>
              <div>3.8用户有下列行为之一的，乾田网络有权暂停用户账号的登录和使用并要求用户改正：</div>
              <div>1） 严重违反本协议的行为；</div>
              <div>2） 提供虚假注册身份信息的；</div>
              <div>3） 通过不正当手段使用乾田网络产品和服务的；</div>
              <div>4） 违反中国的法律禁止性规定的；</div>
              <div>5） 违背社会公德的行为；</div>
              <div>6）其他危害乾田网络或第三方权益的行为。</div>
              <div>3.8.1用户因上述情况被暂停使用账号或永久禁止使用账号的，乾田网络将以适当的方式给予该用户告知并说明理由，用户可以通过乾田网络客服中心申诉。申诉理由成立的，乾田网络可以给予解封处理，恢复用户账户的登录和使用；</div>
              <div>3.8.2 用户账号被临时查封或永久删除之后，用户有事实证明已经在合理期限内改正并保证不再有类似行为的，乾田网络可以给予解封处理，恢复用户账户的登录和使用；</div>
              <div>3.8.3 用户因上述行为导致乾田网络或其他人损失的，应在上述损失得到实质性弥补或赔偿后，才可以申请恢复账户的登录和使用。</div>
              <div>3.8.4 上述情况临时冻结或永久删除用户账户时，乾田网络可以提供后台数据证明支持。但涉及虚拟货币不正常变化以外的情况除外，包括用户通过第三方平台以及其他非通过虚拟等值交换完成的行为等。</div>
              <div>3.9用户不得对本软件以及乾田网络其他产权内容进行修改、反向工程(Reverse Engineering)、译码(Decompile)、反向组译(Disassemble)、复制(copy)或散布(distribute)操作、修改游戏数据传输过程中的各类数据、利用相关工具欺骗服务器、更改本软件程序或增加任何非乾田网络设置的功能等操作。对于本软件相关或因用户的使用而产生的任何信息和数据，未经乾田网络明确同意，用户不得擅自实施包括但不限于以下行为：使用、复制、修改、链接、转载、汇编、发表、出版，建立镜像站点、擅自借助本软件制作或提供有关衍生产品、作品和服务。</div>
              <div>3.10 用户不得使用任何第三方软件对本软件进行修改或施加影响，包括但不限于攻击或干扰服务器、修改游戏封包、使服务器过度负荷；用户违反前述约定构成犯罪的，乾田网络将举报到司法机关以维护自己的合法权益。</div>
              <div>3.11在拥有合法的账号下，用户可以在我们的服务器上传游戏所必须的资料（如谈话、指令等）。</div>
              <div>所传资料禁止含有下列内容（包括但不限于）：</div>
              <div>（a）侵犯任何第三方的权利或利益的；</div>
              <div>（b）违反中国法律、法规、规章及各项互联网规范性文件及国家机关（包括法律法规授权行使国家公权力的组织）通过其他形式发布的规定之行为；</div>
              <div>（c）包含任何毁谤他人、性骚扰、种族歧视、或对儿童有不良影响的内容；</div>
              <div>（d）包含病毒、特洛依木马、定时炸弹、外挂、有害代码等可能对我们系统造成伤害或影响其稳定性的内容。</div>
              <div>我们有权根据上传内容的严重性采取包括法律手段在内的各项必要行为。用户须为此提供必要的合作和配合，并承担上述行为所产生的一切责任。</div>
              <div>3.12用户不得进行任何危害计算机网络安全的行为，包括但不限于：</div>
              <div>（a）使用未经许可的数据或进入未经许可的服务器/帐户；</div>
              <div>（b）未经允许进入他方的网络或他人计算机系统并删除、修改、增加存储信息；</div>
              <div>（c）未经许可，企图探查、扫描、测试本软件系统或网络的弱点或其它实施破坏网络安全的行为；</div>
              <div>（d）企图干涉、破坏本软件系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</div>
              <div>（e）伪造TCP/IP数据包名称或部分名称进行恶意行为。</div>
              <div>3.13 用户不得利用本游戏内出现的、影响游戏公平性的技术及规则漏洞、未经许可的第三方软件、游戏架构的局限性，从事谋取超出本游戏预设的玩家正常使用游戏软件时所能获得的虚拟所得或优势。</div>
              <div>3.13.1对于违反本第3.13条规定的行为，乾田网络有权采取下述措施中的一种或几种给予处理。因乾田网络采取相关措施而导致的损失，概由该用户承担：</div>
              <div>（a）警告；</div>
              <div>（b）全部删除处理所获得的虚拟所得或优势；</div>
              <div>（c）暂时查封其账号；</div>
              <div>（d）永久查封其账号、虚拟角色。</div>
              <div>3.13.2在采取警告、暂时查封其账号等措施的同时，我公司有权从服务器运营数据库删除其不正当获取的虚拟所得或优势，以维护游戏的公平性和诚信、安全的游戏环境；</div>
              <div>3.13.3利用上述技术及规则漏洞、未经许可的第三方软件、游戏架构的局限性牟利，或宣传、传播上述使用方法或者教唆他人传播、使用的，我公司有权在采取上述措施的同时，举报到司法机关，追究其法律责任；</div>
              <div>3.13.4在上述情况之外，因玩家数据异常或明显超出游戏预设的玩家正常使用游戏软件时所能获得的虚拟所得或优势，乾田网络有权采取上述措施。</div>
              <div>3.13.5 在出现重大技术及规则漏洞等异常情况，可能严重破坏游戏公平性设置时，乾田网络有权单方决定紧急状态下的技术性停机维护；在相关破坏游戏公平性设置的因素散布较广并已经危害绝大部分玩家利益时，乾田网络有权根据保护整体玩家利益的需要采取回档操作。</div><br>
              <div class="blod">4. 个人资料的保护及其限制：</div>
              <div>4.1未经用户许可，乾田网络将不向任何第三方公开或共享乙方注册资料中的姓名、个人有效身份证件号码、联系方式、家庭住址等个人身份信息，但下列情况除外：</div>
              <div>1）用户或用户监护人授权披露的；</div>
              <div>2）有关法律要求披露的；</div>
              <div>3）司法机关或行政机关基于法定程序要求提供的；</div>
              <div>4）乾田网络为了维护自己合法权益而向用户提起诉讼或者仲裁时；</div>
              <div>5）应用户监护人的合法要求而提供用户个人身份信息时。</div>
              <div>6）在紧急情况下为保护第三人的人身财产安全的情形下。</div>
              <div>4.2用户基于其个人的需要，要求查询其他用户资料和信息，应事先获得公安机关的查询公函或通过其他法定程序进行，否则乾田网络有权拒绝。</div>
              <div>4.3 对于用户个人信息资料，用户同意乾田网络及关联企业或合作对象，可以在合理范围内搜集、处理、保存、传递及使用，以提供用户其它信息及服务或其它任何合法使用，但不得违背保护用户信息秘密的强制性规定。</div>
              <div>4.4乾田网络有权不定时地向用户发送业务公告等信息，通过邮件或短信等方式。</div>
              <div>4.5鉴于互联网服务的特殊技术安全情况和政策管理要求，尽管乾田网络对用户的隐私权保护做了极大的努力，但是仍然不能保证现有的安全技术措施使用户的技术信息等不受任何形式的损失，用户对此予以理解和接受。另外，为了更新程序的需要，必要时可视为乾田网络从用户处获得下列授权：</div>
              <div>（a）从用户的计算机上传档案；</div>
              <div>（b）下载档案到用户的计算机。</div><br>
              <div class="blod">5.实施网络防沉迷系统的目的：</div>
              <div>5.1.1防止未成年人过度沉迷网络游戏，倡导健康游戏习惯，保护未成年人的身心健康。</div>
              <div>5.1.2帮助家长及法定监护人了解其未成年子女或其他法定监护对象是否使用《舞侠OL》网络游戏等情况。</div>
              <div>5.2未成年人网络游戏防沉迷系统开发标准：</div>
              <div>5.2.1 未成年人累计在线3小时以内的游戏时间为“健康”游戏时间。</div>
              <div>5.2.2 未成年人累计在线游戏3小时之后，再持续下去的2小时游戏时间为“疲劳”游戏时间，此时间段内未成年人获得的游戏收益将降为正常值的50%。</div>
              <div>5.2.3 未成年人累计在线游戏时间超过5小时为“不健康”游戏时间，不健康时间内未成年人的游戏收益降为0。</div>
              <div>5.3 未成年人网络游戏防沉迷系统运行开始时间：本游戏网络游戏防沉迷系统的正式实施，将根据国家的有关通知执行。</div>
              <div>5.4 实名信息的补正、修改：</div>
              <div>5.4.1实名信息的补充填写：在上述正式实施防沉迷系统之前已注册帐号的用户（以下简称“老用户”），享有为期6个月（自乾田网络根据国家的有关通知正式实施防沉迷系统之日起算）的实名身份信息补充填写权，补充填写期限满后老用户未补充填写实名身份信息，乾田网络将依照其原有信息进行实名认证。请用户留意乾田网络的相关书面通知。</div>
              <div>5.4.2老用户在补充填写期限内提供的实名身份信息仅作为判断是否纳入网络游戏防沉迷系统之用，不替代老用户原有注册信息，不作为判断该帐号权益归属的有效依据。与老用户权益归属相关的一切判断均依照原有信息进行，原有信息缺失的情况下，可适当考虑参照补充填写的实名身份信息。</div>
              <div>5.4.3在补充填写期限内，如出现冒用老用户名义提供实名身份信息的情况，可在乾田网络或司法机关对帐号归属权进行验证判断后，允许老用户通过合法流程更改帐号的实名身份信息。</div>
              <div>5.4.4新用户首次注册实名身份信息和老用户的首次补充实名身份信息均为免费，用户的实名身份信息一经注册或者补充即不允许随意修改。</div>
              <div>5.4.5我们在此特别告知用户：由于防沉迷系统等实名认证系统在实施上的政策强制性，为了保护全体用户的利益免受不利影响，请用户在首次注册乾田网络时就填写并使用真实的身份信息，并请用户在注册时仔细核对、确认所提交的信息。如因用户填写、提交的身份信息问题，导致用户在国家有关防沉迷系统实施的过程中，出现任何无法预见的不便或损失，用户需要自己负责。</div>
              <div>5.5 乾田网络认证原则</div>
              <div>5.5.1 未满18周岁的用户将被判定为未成年人。</div>
              <div>5.5.2 达到或者超过18周岁的用户将被初步判定为成年人。</div>
              <div>5.5.3 补充填写期限到期后，未进行补充实名身份信息并且原注册信息不符合实名身份要求的老用户将被判定为未成年人。新用户注册的实名身份信息不符合实名认证要求的，将被判定为未成年人。</div>
              <div>5.5.4 上述情况按照以下原则处理：</div>
              <div>5.5.4.1被判定为未成年人的用户纳入网络游戏防沉迷系统。</div>
              <div>5.5.4.2初步判定为成年人的用户，其实名身份信息将由乾田网络提交公安部门进行验证。</div>
              <div>5.5.4.3当未成年人用户年龄自然成长达到18周岁时，系统自动初步认定其为成年人，其实名身份信息将由乾田网络提交公安部门进行验证。</div>
              <div>5.5.5 对于不适用中华人民共和国身份证信息注册的现役军人、外籍人士、港澳台同胞等用户，可根据其合法身份证明复印件或传真件向乾田网络提出实名认证申请，认证未通过前纳入网络游戏防沉迷系统。</div>
              <div>5.6 验证须知</div>
              <div>5.6.1 乾田网络定期将经识别分类后初步判定为成年人的实名身份信息批量提交公安部门进行验证，由公安部门判定该信息是否真实，并根据验证结果最终确定该用户是否属于成年人。</div>
              <div>5.6.2乾田网络将建立实名身份信息数据库，逐步积累已经验证的实名身份信息，用于对新用户提供的实名身份信息进行比对，比对一致的将不再重复送交公安部门验证。</div>
              <div>5.6.3 用户实名身份信息经公安部门验证未通过的将被纳入网络游戏防沉迷系统。</div>
              <div>5.6.4 乾田网络无法确保公安部门现有身份信息库的完整，用户对此现状已明确了解。用户提供本人的身份证复印件、户口薄复印件、联系电话等真实有效的身份证明后，可向乾田网络提出将使用本人身份信息注册的帐号归入网络游戏防沉迷系统的要求。如用户需进一步主张自身的权利，根据相关法规和程序处理。</div>
              <div>5.6.5乾田网络自用户提交实名身份信息后一个自然月内完成验证。</div>
              <div>5.7 查询系统须知</div>
              <div>5.7.1 网络游戏实名认证信息查询系统，供家长及法定监护人对其未成年子女或其他法定监护对象是否使用网络游戏，是否受到网络游戏防沉迷系统的保护等情况进行查询，以及供公民查询本人身份信息有无被他人使用的情况。</div>
              <div>5.7.2 查询者在使用查询系统时需通过书面形式提出申请，并附本人的身份证复印件、户口薄复印件、联系电话、邮编、邮寄地址或电子邮件等真实有效的身份证明信息，方可向乾田网络提出查询本人身份信息有无被他人使用的申请；如需依法查询被监护人是否使用网络游戏，需要另外提供经公证的能够证明本人与被监护人法定监护关系的证明材料。</div>
              <div>5.7.3 公民如发现本人身份信息被他人使用，在提供本人经公证的身份证复印件、户口薄复印件、联系电话等真实有效的身份证明后，可向乾田网络提出将使用本人身份信息注册的帐号归入网络游戏防沉迷系统的要求。如公民需进一步主张本人的权利，应根据相关法律和程序处理。</div>
              <div>5.7.4 对于用户的查询需求，自收到申请函之日起乾田网络需在一周内对身份信息的表面一致性进行核实后，将处理情况复函通知查询者。</div><br>
              <div class="blod">6 用户使用互联网游戏的限制：</div>
              <div>6.1 乾田网络严禁用户利用游戏从事与本协议目的不符的行为，不得利用游戏从事非法活动：包括但不限于侵害他人合法权益；传播色情或其它违反社会公德的言论；传输或散布计算机病毒；从事广告或其他不法交易；散布虚假不实信息等违法犯罪行为。</div>
              <div>6.2 用户有义务合理、合法使用乾田网络用户咨询服务平台，对于非法干扰乾田网络服务平台正常的工作秩序的行为，乾田网络有权采取合理措施并在必要时举报到司法机关处理。</div>
              <div>6.3用户购买乾田网络一卡通及其他游戏物品时，应从乾田网络指定渠道购买，以确保所购物品的合法性和安全性。用户从他处购买前述物品，全部可能的风险和损失由用户自行承担，乾田网络不承担任何责任。</div>
              <div>6.4 用户就其在游戏的全部行为和活动承担责任，与乾田网络无关。用户利用游戏进行违法犯罪或违背中国互联网管理政策要求的行为，乾田网络有义务采取及时、合理的措施进行干预，并在必要时举报到司法机关处理。导致乾田网络损失的，应给予及时、全额的赔偿。</div>
              <div>6.5 乾田网络互联网游戏禁止使用任何游戏外挂程序，由此引起的一切问题及后果，由用户自行承担，乾田网络不承担任何责任，并保留对外挂制作者诉诸司法机关的权利。</div>
              <div>6.6 对于用户的违反本协议的行为，乾田网络有权依下列方式予以处分，甚至终止此协议（包含软件授权书与用户的账号），用户无权对可能造成的任何损失要求乾田网络给予赔偿或补偿，造成乾田网络损失的，乾田网络可以采取合理合法措施予以追偿：</div>
              <div>（a）强制恢复用户账号中的数据记录，如扣除人物等级金钱等；</div>
              <div>（b）删除用户帐号中的数据记录，如删除人物或者删除虚拟道具等；</div>
              <div>（c）向所有的游戏玩家标识用户的违规违法行为；</div>
              <div>（d）停权，记入游戏黑名单（即用户的账号或角色将无法进行游戏）：一般视违规轻重给予1天~30天不等之停权处分，若有重大违规者，给予永久停权之处分（亦即该账号永远无法再进行游戏）。</div>
              <div>除本协议另有约定者外，乾田网络可以根据情况决定不再提供游戏服务，乾田网络亦可单方面终止此协议，且不退还任何费用。</div><br>
              <div class="blod">7. 服务的停止和更改：</div>
              <div>7.1如因系统维护或升级的需要而需暂停网络服务，乾田网络将尽可能事先进行通告：</div>
              <div>7.2如发生下列任何一种情形，乾田网络有权随时中断或终止（包括部分中断或终止）向用户提供本协议项下的网络服务而无需通知用户：</div>
              <div>7.2.1 游戏运营出现与乾田网络商业目标不符的情况；</div>
              <div>7.2.2乾田网络的互联网设备进行必要的保养及施工；</div>
              <div>7.2.3 发生突发性的互联网设备故障；</div>
              <div>7.2.4乾田网络所用的互联网通信设备由于不明原因停止，无法提供服务；</div>
              <div>7.2.5 由于不可抗力因素致使乾田网络无法提供互联网游戏服务；</div>
              <div>7.2.6 相关政府机构的要求；</div>
              <div>7.2.7其他非乾田网络过错所造成的重大客观情况变化，导致乾田网络无法再继续提供游戏服务的。</div>
              <div>7.3 无论任何情形，乾田网络就停止、更改或终止用户账号所可能产生的不便或损害，乾田网络对用户本人或任何第三人均不负任何损害赔偿责任。</div>
              <div>7.4在游戏运营、测试期间，因无法预见、无法避免、无法克服的服务器或软件缺陷造成的运营中断、维护性中断以及其他非乾田网络能主动控制的运营故障损失，乾田网络不承担责任，用户对此予以认可和接受。</div><br>
              <div class="blod">8. 风险承担：</div>
              <div>用户同意使用乾田网络互联网游戏《舞侠OL》是出于用户个人意愿，并愿自负任何风险，包括但不限于其因执行乾田网络互联网游戏或自行由乾田网络游戏官方网站下载游戏或资料图片而导致用户或其所使用的计算机软、硬件的损害，或发生任何资料的流失等。</div><br>
              <div class="blod">9. 链接：</div>
              <div>乾田网络在其官方网站的所有网页上所提供的所有链接，可能链接到其它个人、公司或组织的网站，提供该等网站的目的，是便利用户自行搜寻或取得信息，乾田网络对于被链接的个人、公司或组织的网站所提供的产品、服务或信息，不担保其真实性、完整性、合法性，用户对该等链接和信息有权进行自主取舍，全部责任由用户自行承担。</div><br>
              <div class="blod">10. 乾田网络的终止权：</div>
              <div>用户应确实遵守本服务条款及有关法律法规的规定。乾田网络对于用户是否违反服务条款有最终决定权。若乾田网络认定用户违反服务条款或任何法令者，用户同意乾田网络可以随时终止用户的账号及密码在《舞侠OL》游戏中的使用权。</div><br>
              <div class="blod">11. 损害赔偿：</div>
              <div>用户若违反服务条款或相关法令，导致乾田网络、或其关系企业、受雇人、受托人、代理人或／及其它相关履行辅助人因此而受到损害或支出费用（包括但不限于由法律诉讼、行政程序等所支出的诉讼费用、律师费用、实际遭受损失的费用等），用户应负担赔偿责任。</div><br>
              <div class="blod">12. 广告信息或促销计划：</div>
              <div>乾田网络的互联网游戏软件上可能刊登商业广告、或其它活动促销的广告。这些内容属于广告商或商品服务提供者提供，乾田网络不担保其合法性、真实性和安全性，用户选择接受此类信息的后果，概由用户自己承担。</div><br>
              <div class="blod">13. 青少年用户特别提示：</div>
              <div>保护青少年用户（特别是未成年人）是乾田网络的一项基本政策，因此，若父母（监护人）希望未成年人（尤其是十岁以下子女）得以使用本服务，必须以父母（监护人）名义申请注册，在接受本服务时，应以法定监护人身份加以判断本服务是否符合于未成年人。青少年用户必须遵守全国青少年互联网文明公约，正确、合理使用互联网游戏和服务，正确合理处理学习和娱乐的关系，正确处理人格健康发育和网络人际发展的关系，青少年用户应对与自己年龄、认知能力相符的行为承担全部责任。在游戏防沉迷系统之外，乾田网络有义务配合落实国家有关家长网游监督管理政策的要求，对于青少年用户家长的正确、合法要求，乾田网络在身份信息核实后采取相应的配合处理措施，由此导致的可能损失和影响，乾田网络不承担责任。</div><br>
              <div class="blod">14. 法律责任与免责：</div>
              <div>14.1 乾田网络特别提请用户注意，乾田网络为了保障公司业务发展和调整的自主权，乾田网络拥有随时自行修改或中断软件授权而不需通知用户的权利，如有必要，修改或中断会以通告形式公布于乾田网络站重要页面上。</div>
              <div>14.2 用户违反本协议或相关的服务条款的规定，乾田网络有权视用户的行为性质，采取包括但不限于中断使用许可、停止提供服务、限制使用、回收用户的乾田网络用户帐号、追究法律责任等措施。对利用乾田网络用户帐号进行违法活动、捣乱、或骚扰、欺骗其他用户等行为，公司有权回收其帐号。同时，乾田网络有义务根据司法部门的要求协助调查。</div>
              <div>14.3 使用本软件由用户自己承担风险，乾田网络及合作单位对本软件不作任何形式的担保，不论是明示的、默示的或法令的保证和条件，包括但不限于本软件的适销性、适用性、无病毒、无疏忽或无技术瑕疵问题、所有权和无侵权的明示或默示担保和条件，对在任何情况下因使用或不能使用本软件所产生的直接、间接、偶然、特殊及后续的损害及风险，乾田网络及合作单位不承担任何责任。</div>
              <div>14.4 使用本软件涉及到互联网服务，可能会受到各个环节不稳定因素的影响，存在因不可抗力、计算机病毒、黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何网络、技术、通信线路等原因造成的服务中断或不能满足用户要求的风险，用户须明白并自行承担以上风险，乾田网络及合作单位不承担任何责任。</div>
              <div>14.5 使用本软件可能存在来自他人匿名或冒名的含有威胁、诽谤、令人反感或非法内容的信息的风险，用户对此予以理解并接受自行承担以上风险，乾田网络及合作单位不对任何有关信息真实性、适用性、合法性承担责任。</div>
              <div>14. 6 因技术故障等不可抗事件影响到服务的正常运行的，乾田网络及合作单位承诺在第一时间内与相关单位配合，及时处理并进行修复，但用户因此而遭受的经济损失，乾田网络及合作单位不承担责任。</div>
              <div>14.7 用户通过本软件与其他用户交往，因受误导或欺骗而导致或可能导致的任何心理、生理上的伤害以及经济损失，与乾田网络及合作单位无关。</div>
              <div>14.8 用户在注册时选择或同意公开个人资料，或用户与乾田网络及合作单位之间就用户资料公开或使用另有约定的情况下，用户应自行承担因此可能产生的任何风险，乾田网络对此不予负责。</div>
              <div>14. 9乾田网络和/或合作单位将根据市场与技术的发展向用户提供与本软件相关的各种互联网以及移动通信增值服务，包括免费和收费的增值服务。乾田网络和/或合作单位保留对相关增值服务收取费用及改变收费标准、方式的权利；如相关服务由免费变更为收费服务，乾田网络和/或合作单位将以适当的形式通知，用户可自主选择接受或拒绝收费服务，并保证在使用收费服务时，将按照乾田网络和/或合作单位相关收费规定支付费用，如拒付或拖欠费用，乾田网络和/或合作单位有权停止服务，并依法追偿损失及赔偿。</div>
              <div>14.10 根据国家有关虚拟财产交易的有关规定以及第三方交易平台的安全性问题，乾田网络不支持第三方交易平台虚拟交易中介业务，对于用户在第三方平台进行的虚拟财产交易，乾田网络不承担责任，用户坚持线下或通过第三方平台进行虚拟账号、虚拟资产交易的，全部风险和可能损失由玩家自行承担。</div>
              <div>14.11 乾田网络不支持用户以自己的账号从事账号、虚拟资产交易或任何形式的虚拟流转业务，用户从事上述业务的风险由用户自行承担。用户从事此类业务所形成的风险和损失，乾田网络无义务进行调查、取证或风险追偿。用户要求乾田网络从事上述行为的，应及时取得司法或行政机关的通知，且对因此导致的乾田网络损失承担全部赔偿责任。</div>
              <div>14.12关于网络游戏防沉迷系统：</div>
              <div>14.12.1鉴于现有技术及乾田网络资源的局限性，乾田网络在此特别提示用户在本网络游戏防沉迷系统及实名认证实施过程中可能存在缺陷及瑕疵，用户对此表示理解和认可，同意并自动接受此服务协议的约定内容。乾田网络在依照本协议所述履行相应义务的前提下，用户承诺放弃就此追究乾田网络的责任的权利。</div>
              <div>14.12.2 由于用户向乾田网络提供错误、不完整、不实信息等致使本人遭受任何损失，责任自负。</div>
              <div>14.12.3由于用户个人原因将密码告知他人或未保管好自己的密码或与他人共享注册账号或任何其它非乾田网络的过错，导致本人实名认证信息泄露，乾田网络不承担责任。</div><br>
              <div class="blod">15. “软件”的替换、修改、升级和终止：</div>
              <div>15.1乾田网络保留在任何时候通过为用户提供本软件替换、修改、升级版本的权利以及为替换、修改或升级收取费用的权利。“软件”为用户默认开通“升级提示”功能，视用户使用的“软件”版本差异，提供给用户自行选择是否需要开通此功能。</div>
              <div>15.2如“软件”将停止运营的，乾田网络提前60日发出通知，用户应当在通知发出之日起，作好相关停止运营的准备工作。一旦“软件”停止运营的，乾田网络将不再提供联网和运营服务，该“软件”项下的游戏道具和装备等游戏信息，将一并消失。乾田网络对此不承担补偿责任。</div><br>
              <div class="blod">16 资费政策：</div>
              <div>16.1《舞侠OL》游戏产品和服务的收费信息以及有关的资费标准、收费方式、购买方式或其他有关资费政策的信息，乾田网络将会在《舞侠OL》网站（http://testwx.qtthtech.com）上作出说明。</div>
              <div>16.2用户理解并知晓，乾田网络不提供用户间交易乾田网络休闲币的平台化服务。用户间的交易构成对本协议的违反。对于此类交易，乾田网络有权在未经通知的情况下，采取相应适当措施。</div>
              <div>16.3乾田网络有权决定《舞侠OL》游戏产品和服务的资费标准和收费方式，可能会就不同的产品和服务制定不同的资费标准和收费方式，也可能按照乾田网络所提供的产品和服务的不同阶段确定不同的资费标准和收费方式。</div>
              <div>16.4乾田网络的资费政策如果发生更改，尽可能将提前在《舞侠OL》官方网站（https://wx.qtthtech.com/）的重要页面或登录游戏时的欢迎页面上提示修改内容，敬请注意查看。</div>
              <div>16.5用户需要认真阅读乾田网络的资费政策。用户的使用行为视为对资费政策的接受，并同意接受条款的约束。</div><br>
              <div class="blod">17 其他条款：</div>
              <div>17.1 本协议涉及的游戏规则等内容以乾田网络站上告知的内容为准，前述规则作为本合同的附件，用户同样应当遵守。</div>
              <div>17.2本协议所定的任何条款的部分或全部无效，不影响其它条款的效力。</div>
              <div>17.3本协议自用户接受本协议之条款起生效，至《舞侠OL》停止运营之日起终止。</div>
              <div>17.4本协议的解释、效力及纠纷的解决，适用于中华人民共和国法律。若用户和乾田网络之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，诉诸上海市黄浦区人民法院。</div>
              <div>17.5本协议项下所有的通知均可通过页面公告、电子邮件或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。</div><br>
              <div class="blod">18. 关于本游戏的相关服务是由乾田网络所提供。乾田网络通过官网或与本游戏相关页面发布的信息包括通知、新规则、注册协议等，视为本协议的有效部分。</div>
              <div>18.1用户可以通过《舞侠OL》官方网址（https://wx.qtthtech.com/）联络乾田网络并了解相关的游戏规则。遇有任何问题我们将尽快为用户处理。如果用户对这些条款有任何疑问，可以询问我们的客服部门。为了用户的利益，请你认真填写正确用户的Email地址和身份证号码及其他密码保护资料，以便我们更好地为用户服务。</div>
              <div>18.2 乾田网络欢迎用户以各种方式对我们的服务进行监督，在乾田网络用户服务平台以外，通过其他多种渠道提出建设性的建议、反馈游戏运营和用户服务中需要改善和提高的内容，但乾田网络同样对于利用互联网服务技术性、时代性局限进行恶意炒作乃至侵害公司权益的违法犯罪行为，有权采取合理合法的针对性措施；为确保营造和谐、多赢的网游服务关系，共同促进、提高乾田网络用户服务水平，我们在此特别提醒用户：</div>
              <div>如果对我们的服务有所建议和申诉，应通过国家正式认可的上海市消费者协会及其各区县分会转达投诉和申诉，对于其他非经国家正式认可的投诉处理机构，乾田网络可以不予接受。同时，对于下述情况，乾田网络有权采取本协议允许的针对性措施，必要时举报到司法机关处理：</div>
              <div>1）无理取闹，蓄意破坏乾田网络用户服务平台工作秩序的；</div>
              <div>2）提出因当时技术、产业、商业条件局限无法解决的要求，并透过非经国家正式认可的投诉服务机构或媒体，歪曲事实进行恶意宣传、夸大宣传，破坏乾田网络声誉和形象的；</div>
              <div>3）蓄意制造事端或捏造事实，从事危害乾田网络正常经营管理、谋取不当利益的；</div>
              <div>4）其他利用用户服务平台危害互联网运营环境、危害乾田网络或其他用户利益的。</div><br>
              <div class="blod">具体联系方式如下：</div>
              <div>《舞侠OL》官方网站地址：https://wx.qtthtech.com/</div>
              <div>《舞侠OL》客服邮箱：service@qtthtech.com</div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import menuWX from '@/components/Menu'
  import downItemWX from '@/views/downLoad/item'
  import axios from "axios";
  export default {
    name: 'news',
    components:{
      menuWX,
      downItemWX,
    },
    data(){
      return {
        type:this.$route.query.type?this.$route.query.type:0,
      }
    },
    created() {
    },
    mounted() {
      document.documentElement.scrollTop = 0
    },
    methods: {
    }
  }
</script>

<style lang="less" scoped>
  .newsWrap{
    .newsBar{
      padding:40px 40px 50px 40px;
      box-sizing: border-box;
      background: rgba(255,255,255,0.8);
      border-radius: 8px;
      .title{
        padding: 0 15px;
        margin-top:26px;
        margin-bottom: 10px;
        height: 24px;
        font-size: 24px;
        color: #333333;
        line-height: 28px;
        letter-spacing: 2px;
        font-weight: 700;
      }
      .des{
        padding: 0 15px;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: normal;
        color: #0057FF;
        line-height: 23px;
        font-weight: 700;
      }
      .des1{
        padding:0 15px 0 5px;
      }
      .con{
        margin-bottom: 10px;
        p{
          position: relative;
          z-index: 0;
          padding-left:15px;
          font-family: JiangChengYuanTi, JiangChengYuanTi;
          font-weight: normal;
          color: #333333;
          line-height: 24px;
          &:before{
            position: absolute;
            left: 0;
            top:8px;
            z-index: 1;
            display: inline-block;
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #333333;
          }
          span{
            font-size: 14px;
            color: #FF0000;
          }
          img{
            margin-top:-5px;
            vertical-align: middle;
          }
        }
        .red{
          &:before{
            background: #FF0000;
          }
        }
      }
      .pic{
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
      }
      .des10{
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 20px;
        span{
          font-size: 20px;
          line-height: 25px;
          color: #FF0000;
        }
      }
      .title10{
        font-size: 24px;
        line-height: 35px;
        font-weight: 900;
        margin: 60px 0;
      }
    }
  }
  .agreementWrap{
    div{
      font-size: 18px;
      font-weight: 400;
      color: #333333;
      line-height: 29px;
    }
    .title2{
      padding: 0 15px 10px;
      margin-top:26px;
      margin-bottom: 30px;
      height: 24px;
      font-size: 24px;
      color: #D22190;
      line-height: 30px;
      letter-spacing: 2px;
      font-weight: 700;
      text-align: center;
      border-bottom: 1px solid #999999;
    }
    .blod{
      font-weight: 700;
    }
    /*.txtInd{
      text-indent:30px;
    }
    .disFlex{
      display: flex;
      .num1{
        font-size: 18px;
        min-width:30px;
      }
      .num2{
        font-size: 20px;
        padding-left: 30px;
      }
      .num3{
        font-size: 20px;
        padding-left: 40px;
      }
    }*/
  }
</style>
